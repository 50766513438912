html {
    overflow: hidden;
    overscroll-behavior: none;
}

@font-face {
  font-family: 'Helveticax';
  src: local('Helveticax'), url(./fonts/HelveticaNeue/HelveticaNeueMedium.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
body {
  margin: 0;
  font-family: 'Helveticax', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0d75a0;
}

html, body { height: 100%; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Player {
  text-align: center;
  width: 100%;
  height: 100%;
}

input {
  max-width: 75%;
  font-size: 150%;
  text-align: center;
  border-radius: 2ch;
  text-transform: uppercase;
}

.InputField {
  /* padding: 1em; */
  /* margin-bottom: 100px; */
  margin-top: 10px;
}

  /* .Translator-inner-child{
    display: flex;
    justify-content: center;
    height: 100vh;
    width: calc(100vh * 9/16);
  } */
.InputField-Translator {
  /* padding: 1em; */
  margin-bottom: 10px;
  margin-top: 10px;
  width: 80%;
  font-family: 'Helveticax', sans-serif;
  overflow-y: auto;
}

.btn-primary {
  cursor: pointer;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  font-weight: 700;
  font-size: 110%;
}

.btn-secondary {
  padding: 0.5em;
  border-color: #ffffff;
  color: #ffffff;
  border-radius: 0.5em;
  font-weight: 400;
  font-size: 90%;
  margin-bottom: 10%;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 2%;
  background-color: transparent;
  vertical-align: middle;
}

.btn-secondary-track {
  padding: 25px;
  border-color: #ececf1;
  color: #091731;
  border-radius: 24px;
  font-weight: 400;
  font-size: 90%;
  margin-bottom: 10%;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 20px;
  background-color: #fff;
  vertical-align: middle;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3px;
}
.btn-secondary-track-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Helveticax", sans-serif;
  font-size: 12px;
  color: #091731;
  padding-left: 10px;
  padding-right: 10px;
}
.audio-progress-container {
  height: 4px;
  background-color: #ddd; /* Gray background color */
  overflow: hidden;
  width: 100%;
}
.audio-progress {
  height: 100%;
  background-color: blue; /* Blue progress color */
}
.audio-progress::before {
  content: '';
  display: block;
}

.buttonOverflowEllipsis{
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.translation-row-first {
  margin-top: 20px;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

.translation-row-last {
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  margin-bottom: 130px !important;
}

.translation-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 25px;
  padding-left: 20px;
  border: none;
  margin-bottom: 2px;
  background-color: #fff;
}

.primary {
  color: #ffffff;
  padding-left: 10px;
  /* background-color: #ffffff47; */
  height: 50px;
}

.primary img {
  width: 50px !important;
}

.translation-row img {
  width: 30px;
  fill: white;
}

.translation-row span {
  margin-right: auto;
  margin-left: 20px;
}

.small-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 25px;
  margin-top: 10px;
  font-size: .7em;
  vertical-align: middle;
  justify-content: center;
}

.small-link img {
  width: 30px;
  fill: white;
}

.domainFlag {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  background-color: red;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  z-index: 10000;
}

.backNavBtnWrapper {
  position: absolute;
  top: calc(0% + 10px);
  right: calc(100% - 40px);
}

.backNavBtn {
padding: 0;
  background-color: transparent;
  border: none;
}

.backNavBtnImg{
  padding: 0;

}

.infoBtnWrapper {
  position: absolute;
  top: calc(0% + 10px);
  left: calc(100% - 40px);
}

.infoBtn {
padding: 0;
  background-color: transparent;
  border: none;
}
.infoBtnImg{
  padding: 0;

}