.tooltipContainer{
    border-radius: 8px;
    background: #fff;
    color: black;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    max-width: 320px;
    text-align: left;
    /*border: 2px solid rgba(0, 0, 0, 0.30);*/
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.50);
}
.tooltipContainerGray {
    border-radius: 8px;
    background: #fff;
    color: black;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    max-width: 320px;
    text-align: left;
    /*border: 2px solid rgba(0, 0, 0, 0.30);*/
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.50);
    /*box-shadow: 0 0 0 2px #ececf1;*/
    /*box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);*/
}

.tooltipFooter {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    color: #0d75a0;
    font-size: 16px;
    font-weight: 600;
}

.tooltipHeader {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
}

.tooltipContent {
    color: #7c7da0;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.tooltipContentLine {
    text-align: left;
    margin-bottom: 0;
}

.tooltipTitle {
    color: #091731;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.tooltipNumber {
    color: #7c7da0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}