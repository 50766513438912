video {
    width: 100%;
    max-width: 640px;
  }

  .startTour {
    font-family: "Helveticax", sans-serif;
    font-weight: 600;
    color: #fff;
    /* width: 339px; */
    text-align: center;
    font-size: 32px;
    padding: none;
  }
  .startTourDetail {
    font-family: "Helveticax", sans-serif;
    font-weight: 400;
    color: #fff;
    width: 100%;
    /* width: 339px; */
    text-align: center;
    font-size: 16px;
    padding: none;

  }
  .streamIdPresent {
    font-family: "Helveticax", sans-serif;
    font-weight: 600;
    color: #fff;
    width: 339px;
    text-align: center;
    font-size: 48px;
    text-transform: uppercase;
  }

  .image-container {
    width: 168px; 
    height: 212px; 
    overflow: hidden; 
    border-radius: 16px;
  }
 
  .image-container img {
    object-fit: cover;
  }
 
  .image-detail-container {
    width: 100%;
    height: 100%;
  }

  .image-detail-container img {
    object-fit: contain;
  }

  .block {
    height: 56px;
    width: 52px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: 14px;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    padding: 16px;
    margin-right: 3px;
    color: white;
  }

  .margin-block {
    margin-right: 12px;
  }

  .block:focus{
    border: 1px solid rgba(255, 255, 255, 1.0);
    outline-width: 0;
  }
  


  .vox-button-container {
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
  }
  
  .vox-button-inner-container {
    position: absolute;
    width: 240px;
    bottom: 20px;
    left: calc(50% - 120px);
  }
  .vox-button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: Helveticax;
    border-radius: 15px; /* Adjust the border-radius for rounded corners */
    background-color: #fff; /* Set your desired background color */
    color: #0d75a0; /* Set your desired text color */
    cursor: pointer;
    gap: 0.3rem;
  }
  
  .vox-button-elem {
    padding: 0;
    margin: 0;
    text-align: center;
    text-decoration: none;
    font-size: 1.5rem; /* Adjust font size as needed */
    line-height: 1.5rem;
  }
  
.App-logo-v1 {
  margin-top: calc(0% + 10px);
  margin-bottom: 40px;
  height: 40px;
  width: 36px;
}

.App-logo-modal-v1 {
  margin-top: calc(0% + 10px);
  margin-bottom: calc(0% + 10px);
  height: 40px;
  width: 36px;
}
.App-logo {
  height: 19vh;
  pointer-events: none;
  margin-bottom: 3em;
  margin-top: 5em;
}

.App-logo-sm {
  height: 15vh;
  pointer-events: none;
  margin-bottom: 4em;
  margin-top: 3em;
}
  .App-logo-xsm {
    height: 8vh;
    margin-bottom: 2em;
    margin-top: 2em;
  }
.App-header-player {
  background-color: #ececf1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: #091731;
  overflow: hidden;
  overscroll-behavior: none;
}
.App-header {
  background-color: #0d75a0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
  overscroll-behavior: none;
}

.App-header-translator {
  background-color: #ececf1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
  overscroll-behavior: none;
}
.App-header-translator-list {
  background-color: #ececf1;
  /* min-height: 100%; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: #091731;
  overflow-y: auto;
}

.Player-translator-list {
  position: fixed;
  height: 100%;
  width: 100vw;
  /* max-height: 100%; */
  overflow: auto;
}

.Translator-back {
  margin-bottom: 10px;
}

.Translator-spacer {
  flex: 1;
}

.presenterMessages {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1px;
  width: 100%;
}

.presenter-message-inner {
  display: flex;
  width: 50%;
  height: 24px;
  justify-content:flex-start;
  gap: 5px;
}

.messages-control {
  display: flex;
  justify-content: space-between;
}

.close-stream {
  background-color: #f03529;
  height: 56px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  font-family: 'Helveticax', sans-serif;
  border-radius: 16px;
  display: flex;
  gap: 10px;
  padding: 20px;
  justify-content: space-around;
}

.messages-text {
  color: #091731;
  font-size: 16px;
  text-align: left; 
}

.transcription-container {
    display: flex;
    background-color: #97D3F333;
    border-radius: 24px;
    padding: 15px;
    width: 80%;
    min-height: 6em;
    align-items: center;
    vertical-align: bottom;
}

.transcription {
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.active-track{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.active-track-playing {
  display: flex;
  align-items: center;
  font-size: .7em;
}

.badgePadding{
  margin-left: -0.6em;
  margin-top: -0.3em;
  box-sizing: border-box;
}

.logo-presenter-block-player {
  display: flex;
  justify-content: center;
}
.logo-presenter-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.voxModalWrappper{
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 100;
}

.flagImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
}

.voxModalBackground{
  width: 100vw;
  height: 100%;
  background-color: #ececf1;
  position: fixed;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}

.singleMessage {
  border: #e1e1ea solid 1px;
  background-color: white;
  border-radius: 16px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 6px;
  padding: 5px;
}

/** new message  **/
.newMessage {
  position: absolute;
   bottom: 3vh;
  /*right: 8vw; */
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: #0d75a0;
  border-radius: 16px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  gap: 5px;
}


.messageName {
  margin: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  height: 56px;
}
.messageText {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.createMessage label {
  text-align: left;
  display: block;
  color: #999;
  font-size: small;
  padding-left: .7rem;
}

.createMessage input, .createMessage textarea {
  width: 100%;
  max-width: 100%;
  border-radius: 12px;
  padding: 6px 10px;
  border: 1px solid #555;
  box-sizing: border-box;
  display: block;
  text-align: left;
  
}

.createMessage  input[type=text]:focus, textarea:focus {
  border: 2px solid #555;
  /* outline: none; */
  outline-color: #555;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-transform: none;
}
.sendMessageOuter {
  display: flex;
  justify-content: center;
  align-items: center;

}
.sendMessage {
  position: absolute;
  bottom: 40px;
  background-color: #0d75a0;
  border: none;
  border-radius: 16px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: white;
}

.voxModalContainer{
  width: 100%;
  height: 100vh;
  /* height: 89%; */
  /* border-radius: 12px; */
  /* background-color: white; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  display: flex;
  flex-direction: column;
  overflow:auto ;
}
.voxModalTitle{
  /* background-color: #003c50; */
}

.modaltitle{
  width: 4rem;
  height: 4rem;
}

.closeVoxModal{
  background-color: transparent;
  position: absolute;
  border: none;
  top: 70px;
  right: 3%;
  z-index: 999;
}

.closeBtn{
  width: 35px;
  height: 35px;

}

.voxModalMessagesContainer {
  width: 100%;
  /* width: 95%; */
  height: 100vh; 
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.voxModalMessagesBody{
  display: flex;
  flex-wrap: wrap;
  font-family: 'Helveticax', sans-serif;
  color: #091731;
  justify-content: flex-start;
  background-color: transparent;
  flex-direction: column;
}

.voxModalMessagesBody > .messageTitle {
  color: #091731;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  padding-left: 5%;
  padding-right: 5%;
} 

.messageName > input {
  font-family: 'Helveticax', sans-serif;
  border-radius: 14px;
  border: #ececf1 solid 1px;
  padding: 16px;
  background-color: white;
  padding-right: 20px;
  text-transform: none;
}



 .messageName > input::placeholder {
  font-family: 'Helveticax', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #7c7da0; 
  text-transform: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #091731;
    transition: background-color 5000s ease-in-out 0s;
}

.messageText > textarea {
  border-radius: 14px;
  border: #ececf1 solid 1px;
}

.voxMessage {
  font-size: small;
  text-align: left;
  padding: 0.7rem;
  background-color: white;
  border-radius: 6px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.voxTimestamp {
  font-size: x-small;
  text-align: right;
  padding-right: 1rem;
  color: #7c7da0;
}

.voxModalBodyContent {
  display: flex;
  justify-content: center;
  border-radius: 16px;
}
.voxModalBodyContentContainer {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: white;
  padding-top: 8px;
  margin-bottom: 10px;
}
.voxModalBodyContentHeader {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: 'Helveticax', sans-serif;
  color: #091731;
}
.voxModalBody{
  display: flex;
  flex-wrap: wrap;
  color: black;
  justify-content: flex-start;
}
.voxModalBody > div {
  margin-bottom: 8px;
  flex: 0 50%;
}

.voxModalFileContainer {
    background-color: white;
    border-radius: 16px;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  max-height: 100%;
  overflow: hidden;
}
.voxModalFileBody{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
  height: 90%;
}

.player-main-body{
  flex: 1;
  /* margin-top: 30px; */
  width: 90%;
}

.joinstream-footer-filler{
}

.Player{
    position: fixed;
    height: 100%;
    width: 100vw;
    max-height: 100%;
    overflow: hidden;
    overscroll-behavior: none;
  }

  /* .Player-joinstream-child{
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: #0d75a0;
    justify-content: space-around;
  }
  .Player-streamplayer-child{
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: #ececf1;
    justify-content: space-around;
  }
  .Player-inner-child{
    height: 100vh;
    width: calc(100vh * 9/16);
  } */

.infoModalWrapper{
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 100;
}

.infoModalBackground{
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.infoModalContainer{
  margin-top: 110px;
  width: 250px;
  max-height: 500px;
  font-family: Heveticax, sans-serif;
  /* margin-bottom: 28px; */
  border-radius: 24px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.info-App-logo {
  height: 70px;
  pointer-events: none;
  margin-bottom: 15px;
  margin-top: -15px;
}
.close-btn-info {
  position: relative;
  top: 20px;
  right: -90px;
}

.player-footer {
    margin-bottom: 75px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@media (max-width: 399px){
  /* .image-container {
    width: 150px; 
    height: 189px; 
  } */

  .close-stream {
    margin-bottom: 10px;
  }
}
  .player-footer {
    margin-bottom: 75px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .player-footer-player {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
@media (min-width: 400px) and (max-width: 575px){

  .voxModalBackground{
    height: 100% !important;
  }
}

@media (min-width: 700px) and (max-width: 809px){
  .App-logo {
    height: 40vmin;
    margin-top: 2em;
  }

  .App-logo-sm {
    height: 15vmin;
    margin-top: 3em;
  }

  .newMessage {
    bottom: 7vh;
  }
}

@media (min-width: 810px){
  /* .voxModalContainer{
    width: 800px;
  } */
  /* .voxModalMessagesContainer{
    width: 800px;
  } */
  .newMessage {
    bottom: 80px;
    right: 26vw;
  }
  .closeVoxModal{
    top: 49px;
    left: 760px;
    z-index: 999;
  }

  .App-logo {
    height: 40vmin;
    margin-top: 1em;
  }

  .App-logo-sm {
    height: 20vmin;
    margin-top: 1em;
  }
}

.joinstream {
  margin-bottom: 75px;
}

.version {
  color: black;
  font-size: calc(5px + 2vmin);
  position: absolute;
  background-color: transparent;
  top: 97%;
  right: 0;
  left: 0;
  bottom: 0;
  margin-left: auto;
  text-align: end;
}

@media (max-height: 620px) {
  .App-logo {
    height: 13vh;
    margin-bottom: 2em;
    margin-top: 2em;
  }

  .App-logo-sm {
    height: 8vh;
    margin-bottom: 2em;
    margin-top: 2em;
  }
  
  .App-logo-xsm {
    height: 7vh;
    margin-bottom: 1em;
    margin-top: 1em;
  }
}